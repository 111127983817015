"use client";

import { usePathname } from "next/navigation";
import AdsBanner from "./AdsBanner";
import Header from "./Header";
import TrendingTag from "./TrendingTag";
import CTANewsSection from "./CTANews";
import Footer from "./Footer";

const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname();
  const isNewsDetailPage = pathname?.startsWith("/tin-tuc/");

  return (
    <>
      <AdsBanner />
      <Header />
      <TrendingTag />
      <main className="max-w-[1440px] mx-auto px-4 md:px-10">{children}</main>
      {!isNewsDetailPage && <CTANewsSection />}
      <Footer />
    </>
  );
};

export default ContentWrapper;

