import MyCard from "@/app/components/MyCard";
import { KLARDA_HOME_URL } from "@/constant/apiPath";
import { Button } from "antd";
import React from "react";

const CTANewsSection: React.FC = () => {
  return (
    <MyCard>
      <div className="flex flex-col md:flex-row items-center justify-center gap-6">
        <div className="flex flex-col gap-6 items-center text-center md:text-left">
          <h3 className="text-2xl md:text-4xl font-extrabold">Trở thành đối tác của Klarda</h3>
          <p className="text-lg md:text-xl font-normal text-gray-500 text-center lg:max-w-[700px]">
            Chúng tôi đang tìm kiếm những Dự án hoặc nhóm có động lực để hợp tác xây dựng những sản phẩm hữu ích. Liên
            hệ với chúng tôi ngay hôm nay để biết thêm thông tin
          </p>
          <Button type="primary" className="w-fit" href={`${KLARDA_HOME_URL}/vi/registration-portal`} target="_blank">
            Tham gia ngay
          </Button>
          <div className="text-center text-gray-500 text-sm mt-4">
            Chúng tôi quan tâm đến việc bảo vệ dữ liệu của bạn. Xem thêm{" "}
            <a href="https://inside.klarda.com/privacy-policy" className="text-blue-500 underline">
              Chính sách bảo mật
            </a>
            .
          </div>
        </div>
      </div>
    </MyCard>
  );
};

export default CTANewsSection;
